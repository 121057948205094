<template>
  <div>
    <h2 class="is-size-3">
      Beschikbaarheid
    </h2>
    <div class="content">
      <p>
        Meld je aan voor <strong>elke</strong> periode waarin je beschikbaar bent,
        <strong>ook</strong> als het vak dat je wilt volgen niet wordt gegeven in de betreffende periode.
        Het kan namelijk voorkomen dat er wijzigingen optreden in de periodes waarin vakken worden aangeboden.
        Tips:
      </p>
      <ul>
        <li>
          We raden je aan (als je de keuze hebt) de cursus zo vroeg mogelijk te volgen,
          vanwege de volgende twee redenen:
          <ol type="1">
            <li>Je profiteert dan ook bij je laatste schoolexamens van de kennis die je opdoet tijdens de cursus.</li>
            <li>
              Bovendien heb je nog meer tijd om te oefenen met de stappenplannen,
              tips en trucs die je bij ons aangereikt krijgt.
            </li>
          </ol>
        </li>
        <li> In de volgende stap kun je aangeven welke van onderstaande periodes je voorkeur heeft.</li>
        <li>
          Bekijk ons <a
            :href="vakkenoverzichtUrl"
            target="_blank"
          >vakkenoverzicht</a> voor een volledig overzicht van alle vakken die worden aangeboden.
        </li>
      </ul>
    </div>
    <BTable
      :data="openPeriodes"
    >
      <BTableColumn
        v-slot="{row}"
        field="periode"
        label="Periode"
      >
        Periode {{ row.naam }}
        <SslInfoPopover
          v-if="periodeHeeftZichtbareOpmerking(row)"
          title="Opmerkingen"
        >
          <p v-if="row.opmerkingen" v-text="row.opmerkingen" />
          <p
            v-for="cursus in metZichtbareOpmerkingen(gekozenCursussenPerPeriode[row.Periode_ID])"
            :key="cursus.Vak_ID"
          >
            <strong>{{ cursus.naam }}:</strong>
            {{ cursus.ondervoorbehoud ? 'Onder voorbehoud' : '' }}
            {{ cursus.opmerkingen }}
          </p>
        </SslInfoPopover>
        <ul class="periode-dagen">
          <li
            v-for="dag in row.dagen"
            :key="dag"
          >
            {{ dagNaarDagString(dag) }}
          </li>
        </ul>
        <ul class="periode-vakken">
          <li v-if="gekozenCursussenPerPeriode[row.Periode_ID].length === 0">
            geen gekozen vakken in deze periode
          </li>
          <template v-else>
            <li
              v-for="cursus in gekozenCursussenPerPeriode[row.Periode_ID]"
              :key="cursus.Vak_ID"
              :class="cursusDisplayClass(cursus)"
              v-text="cursusDisplayName(cursus)"
            />
          </template>
        </ul>
        <div
          v-if="vergrendelingen.includes(row.Periode_ID)"
          class="content is-small"
        >
          Je beschikbaarheid voor deze periode is vergrendeld omdat je (voorlopig) ingedeeld bent.
        </div>
      </BTableColumn>
      <BTableColumn
        v-slot="{row}"
        field="beschikbaar"
        label="Beschikbaar"
      >
        <SslRadioButtons
          v-model="data[row.Periode_ID]"
          :disabled="vergrendelingen.includes(row.Periode_ID)"
          :options="{0: 'Nee', 1: 'Ja'}"
          :icons="{0: 'close', 1: 'check'}"
          :types="{0: 'is-danger', 1: 'is-success'}"
          @input="() => $v.data.$touch()"
        />
      </BTableColumn>
    </BTable>

    <div
      v-if="vakken.length > 0"
      id="beschikbaarheidWarnings"
      class="warnings-and-errors"
    >
      <BMessage
        v-if="!$v.data.elkVakMinstens"
        key="b-message-elk-vak-minstens"
        type="is-danger"
        has-icon
      >
        Het is verplicht om voor elk vak minstens één periode beschikbaar te zijn.
        Vergroot je beschikbaarheid voor {{ arrayToString(wrongSubjects) }}.
      </BMessage>
      <BMessage
        v-else-if="!$v.data.beschikbaarheidGroterDanVakken"
        key="b-message-beschikbaarheid-totaal"
        type="is-danger"
        has-icon
      >
        Je hebt aangegeven {{ vakken.length }} vakken te willen volgen.
        Zorg dat je tenminste {{ vakken.length }} periodes beschikbaar bent.
      </BMessage>
      <BMessage
        v-else-if="subjectsLessThanAdvised.length > 0"
        key="b-message-beschikbaarheid-per-vak"
        title="Waarschuwing"
        type="is-warning"
        has-icon
        closable
      >
        We raden je aan om voor elk vak minstens {{ beschikbaarheidMinAantal }} periodes beschikbaar te zijn.
        We kunnen je dan vrijwel zeker indelen in een periode van jouw keuze.
        Probeer om meer beschikbare periodes op te geven voor {{ arrayToString(subjectsLessThanAdvised) }}.
      </BMessage>
      <BMessage
        v-if="onlyWachtlijstSubjects.length > 0"
        key="b-message-wachtlijst"
        title="Waarschuwing"
        type="is-warning"
        has-icon
        closable
      >
        Voor {{ arrayToString(onlyWachtlijstSubjects) }} ben je alleen beschikbaar
        in periodes waar een wachtlijst voor is.
        De kans is daarom klein dat er een cursusplek voor je beschikbaar is.
        Probeer om meer beschikbare periodes op te geven.
      </BMessage>
    </div>
  </div>
</template>

<script>
  import {BMessage} from 'buefy/src/components/message';
  import {BTable, BTableColumn} from 'buefy/src/components/table';
  import {computed, ref} from 'vue';

  import SslInfoPopover from '../elements/SslInfoPopover.ts.vue';
  import SslRadioButtons from '../elements/SslRadioButton.vue';
  import sslDateFormatter from '../mixins/SslDateFormatter';

  import {match} from '@/js/util/pattern-match';
  import useVakkenoverzicht, {useVakkenoverzichtProps} from '@/js/vue/util/vakkenoverzicht';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const elkeVakPeriode = function(beschikbaarheid) {
    if (Object.keys(beschikbaarheid).length === 0) {
      return false;
    }
    const gekozenVakken = this.vakken;
    for (const gekozenVak of gekozenVakken) {
      const periodeIDsVanGekozen = Object.keys(gekozenVak.periodes);
      for (const id of periodeIDsVanGekozen) {
        const status = gekozenVak.periodes[id].status;
        if (status !== 'Gesloten' && beschikbaarheid[id] !== '1' && beschikbaarheid[id] !== '0') {
          return false;
        }
      }
    }
    return true;
  };

  const elkVakMinstens = function() {
    return (this.wrongSubjects.length === 0);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const elkVakMinstensAangeraden = function() {
    if ((this.subjectsLessThanAdvised.length === 0) || this.beschikbaarheidAlertGiven) {
      return true;
    }
    this.beschikbaarheidAlertGiven = true;
    return false;
  };

  const beschikbaarheidGroterDanVakken = function(beschikbaarheid) {
    const beschikbaar = Object.values(beschikbaarheid).filter((x) => (x === '1')); // alle beschikbare periodes
    return (beschikbaar.length >= this.vakken.length);
  };

  export default {
    name: 'PartialFormBeschikbaarheid',
    components: {
      BMessage,
      BTable,
      BTableColumn,
      SslInfoPopover,
      SslRadioButtons,
    },
    mixins: [
      sslDateFormatter,
    ],
    props: {
      data: {
        type: Object,
        required: true,
      },
      vakken: {
        type: Array,
        required: true,
      },
      cursusreeks: {
        type: Object,
        required: true,
      },
      beschikbaarheidMinAantal: {
        type: Number,
        default: 1,
      },
      vergrendelingen: {
        type: Array,
        default: () => ([]),
      },
      ...useVakkenoverzichtProps(),
    },
    setup(props) {
      const beschikbaarheidAlertGiven = ref(false);

      // minimum aantal periodes dat aangeraden wordt om beschikbaar te zijn
      const aangeradenPeriodeAantal = computed(() => Math.max(props.beschikbaarheidMinAantal, 1));

      const vakkenoverzichtUrl = computed(() =>
        `https://www.sslleiden.nl/${props.cursusreeks.Trainingstype}/vakkenoverzicht`);

      const {
        openPeriodes,
        cursussenPerPeriode,
      } = useVakkenoverzicht(props);

      const gekozenVakIds = computed(() => props.vakken.map((vak) => vak.Vak_ID));
      const gekozenCursussenPerPeriode = computed(() => Object.entries(cursussenPerPeriode.value).reduce(
        (result, [periodeId, cursussen]) => ({
          ...result,
          [periodeId]: cursussen.filter((cursus) => gekozenVakIds.value.includes(cursus.Vak_ID)),
        }),
        {},
      ));

      const cursusHeeftZichtbareOpmerking = (cursus) => cursus.opmerkingen || cursus.ondervoorbehoud;
      const metZichtbareOpmerkingen = (cursussen) => cursussen.filter(cursusHeeftZichtbareOpmerking);
      const periodeHeeftZichtbareOpmerking = (periode) =>
        periode.opmerkingen || metZichtbareOpmerkingen(gekozenCursussenPerPeriode.value[periode.Periode_ID]).length > 0;

      const cursusDisplayClass = (cursus) => ({
        'is-gesloten': cursus.status === 'Gesloten',
        'is-wachtlijst': cursus.status === 'Wachtlijst',
      });
      const cursusDisplayNameSuffix = (cursus) => match(cursus.status)
        .with('Gesloten', () => 'inschrijvingen gesloten')
        .with('Wachtlijst', () => 'wachtlijst')
        .otherwise(() => null);
      const cursusDisplayName = (cursus) => {
        const suffix = cursusDisplayNameSuffix(cursus);
        return suffix ? `${cursus.naam} (${suffix})` : cursus.naam;
      };

      return {
        beschikbaarheidAlertGiven,
        aangeradenPeriodeAantal,
        vakkenoverzichtUrl,
        openPeriodes,
        cursussenPerPeriode,
        gekozenCursussenPerPeriode,
        cursusDisplayClass,
        cursusDisplayName,
        metZichtbareOpmerkingen,
        periodeHeeftZichtbareOpmerking,
      };
    },
    computed: {
      subjectsCount() {
        const gekozenVakken = this.vakken;
        const res = {};
        for (const vak of gekozenVakken) {
          res[vak.naam] = {};
          const periodesVanGekozen = Object.values(vak.periodes);
          // count is het aantal periodes waarin beschikbaarheid ja is ingevuld voor dit vak
          let count = 0;
          // count2 is het aantal periodes waarin beschikbaarheid ja is ingevuld voor dit vak en er geen wachtlijst is
          let count2 = 0;
          periodesVanGekozen.forEach((periode) => {
            if (this.data[periode.Periode_ID] === '1') { // data = beschikbaarheid
              count = count + 1;
              if (periode.status === 'Open') {
                count2 = count2 + 1;
              }
            }
          });
          if (count < periodesVanGekozen.length) {
            res[vak.naam].beschikbaar = count;
          } else {
            // Geen waarschuwing als alle periodes op 'Ja' staan
            res[vak.naam].beschikbaar = this.aangeradenPeriodeAantal;
          }
          if (
            vak.ingeschreven
            && (
              !vak.aanpasbaar
              || (!vak.wachtlijst && periodesVanGekozen.find((per) => (per.naam === vak.indeling)))
            )
          ) {
            // Geen waarschuwing als het vak niet aanpasbaar is of je al bent ingedeeld
            res[vak.naam].beschikbaarOpen = 1;
          } else {
            res[vak.naam].beschikbaarOpen = count2;
          }
        }
        return res;
      },
      subjectsLessThanAdvised() {
        const counts = this.subjectsCount;
        return Object.keys(counts).filter((key) => (counts[key].beschikbaar < this.aangeradenPeriodeAantal));
      },
      onlyWachtlijstSubjects() {
        const counts = this.subjectsCount;
        return Object.keys(counts).filter((key) => (counts[key].beschikbaarOpen === 0 && counts[key].beschikbaar > 0));
      },
      wrongSubjects() {
        const counts = this.subjectsCount;
        return Object.keys(counts).filter((key) => (counts[key].beschikbaar === 0));
      },
    },
    methods: {
      arrayToString(arr) {
        const last = arr.pop() ?? '';

        return arr.length > 0 ? `${arr.join(', ')} en ${last}` : last;
      },
    },
    validations: {
      data: {
        // elkeVakPeriode,
        elkVakMinstens,
        beschikbaarheidGroterDanVakken,
        // elkVakMinstensAangeraden,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .periodenaam {
    margin-bottom: 0;
    line-height: 1;
  }

  ul.periode-dagen {
    list-style-type: none;
    margin: 0;
    padding-left: 1rem;
    line-break: anywhere;

    > li {
      display: inline;
      font-size: 0.9rem;
      font-weight: 600;
      color: #666;
      white-space: nowrap;
    }

    li + li {
      &::before {
        content: "|";
        margin: 0 0.25rem;
      }
    }
  }

  ul.periode-vakken {
    list-style: none;
    padding-left: 1rem;
    margin: 0 0 0.25rem;
    font-weight: bold;

    li { /* stylelint-disable-line  no-descending-specificity */
      display: inline;

      &::after {
        content: ", ";
      }

      &:last-child::after {
        content: "";
      }
    }
  }

  section {
    margin: 1rem 0;
  }

  .b-table .table.has-mobile-cards tr:not(.detail, .is-empty, .table-footer) td {
    flex-wrap: wrap;
  }
</style>
