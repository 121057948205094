<template>
  <div class="notification is-info">
    <h5 class="title is-5" v-text="nieuwsbericht.Titel" />
    <h5 class="subtitle is-6" v-text="nieuwsbericht.Datum" />
    <span v-html="tekst" /> <!-- eslint-disable-line vue/no-v-html -->
    <div
      v-if="heeftLeesmeer"
      class="has-text-right"
    >
      <BButton
        class="is-primary"
        @click="toggleLeesMeer"
      >
        {{ leesmeer ? 'Lees minder' : 'Lees meer' }}
      </BButton>
    </div>
  </div>
</template>

<script setup lang="ts">
  import {BButton} from 'buefy/src/components/button';
  import {computed, ref} from 'vue';

  import {type NieuwsBericht} from '@/js/types/models/algemeen';

  interface Props {
    nieuwsbericht: NieuwsBericht;
  }

  const props = defineProps<Props>();

  const leesmeer = ref(false);
  const heeftLeesmeer = computed(() => props.nieuwsbericht.Tekst);
  const toggleLeesMeer = () => leesmeer.value = !leesmeer.value;

  const tekst = computed(() => leesmeer.value ? props.nieuwsbericht.Tekst : props.nieuwsbericht.KorteTekst);
</script>
